@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-Book-Pro.otf");
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-BookItalic-Pro.otf");
    font-style: italic;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-XLight-Pro.otf");
    font-weight: 200;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-XLightItalic-Pro.otf");
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-Light-Pro.otf");
    font-weight: 300;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-LightItalic-Pro.otf");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-Medium-Pro.otf");
    font-weight: 500;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-MediumItalic-Pro.otf");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-Semibold-Pro.otf");
    font-weight: 600;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-SemiboldItalic-Pro.otf");
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-Bold-Pro.otf");
    font-weight: bold;
  }

  @font-face {
    font-family: "IdealSans";
    src: url("/public/fonts/IdealSansSSm-BoldItalic-Pro.otf");
    font-weight: bold;
    font-style: italic;
  }

  html {
    font-family: "IdealSans";
  }
}

p {
  @apply font-normal text-base leading-5;
}

.form-input {
  @apply appearance-none w-full py-2 px-3 placeholder:font-normal placeholder:text-base placeholder:leading-5 text-white placeholder:text-white;
}

.default-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  height: 100vh;
  overflow: auto;
}

.wa-flex {
  @apply flex justify-center items-center;
}

.wa-evenly-flex {
  @apply flex items-stretch;
}

.register-bg {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
    ),
    url('../components/UI/Images/RegisterBGImage.gif');
}

.login-bg {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
    ),
    url('../components/UI/Images/LoginBGImage.gif');
}

.register-success-bg {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('../components/UI/Images/SuccessRegistrationBGImage.gif');
}

.forgotPassword-bg {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
    ),
    url('../components/UI/Images/ForgotPasswordBG.gif');
}

.termsConditionsBG-bg {
  background-image: linear-gradient(
      rgba(240, 240, 240, 0.5),
      rgba(240, 240, 240, 0.5)
    ),
    url('../components/UI/Images/TermsAndConditionsBG.gif');
}

.about-page-bg {
  background-image: linear-gradient(
      rgba(240, 240, 240, 0.5),
      rgba(240, 240, 240, 0.5)
    ),
    url('../components/UI/Images/AboutPageBG.gif');
}

.bg-image {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.5)
    ),
    url('../components/UI/Images/DefaultBGImage.gif');
}

.btn-primary {
  @apply bg-black bg-opacity-50 hover:bg-opacity-100 text-white w-48 font-normal leading-7 text-xl py-2 px-4 rounded-full;
}

.btn-close {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e') !important;
}

.custom-captcha {
  transform: scale(1.06);
}

.text-3-5xl {
  font-size: 2rem; /* 32px */
  line-height: 2.125rem; /* 34px */
}

/* Header */
.text-header {
  @apply text-2xl;
}
.hide-sidebar {
  transform: translateX(-285px);
  transition: transform 0.3s;
}
.show-sidebar {
  transform: translateX(0px);
  transition: transform 0.3s;
}
.hide-header {
  transform: translatey(-62px);
  transition: transform 0.3s;
}
.show-header {
  transform: translatey(0px);
  transition: transform 0.3s;
}
.flyout {
  @apply flex flex-col bg-black bg-opacity-90 text-header text-white w-64 absolute inset-y-0 left-0 overflow-y-auto;
  z-index: 1000;
}
.flyout-links {
  @apply pb-8 block font-medium-light hover:font-light hover:text-secondary;
}
.flyout-footer {
  @apply flex flex-col justify-end grow pb-8;
  font-size: 1.5rem; /* 14px */
  line-height: 2.125rem; /* 34px */
}
.flyout-footer > * {
  @apply flex justify-center;
}
.hamburger-stick {
  @apply block w-10 h-2 bg-black rounded-2xl;
}
.header-item {
  @apply lg:p-4 py-2 block font-light hover:font-medium-light hover:text-secondary;
}
.header-links {
  @apply flex flex-wrap items-center justify-start w-full py-4 bg-transparent lg:px-4 lg:py-2 lg:bg-black lg:!bg-opacity-60;
}
.page-title {
  @apply w-auto grow wa-flex text-3xl -ml-10 leading-9 font-light not-italic;
}
/* Header ends */

/* Signout */
.signout {
  @apply lg:flex lg:flex-row lg:justify-end lg:items-end lg:grow text-xl leading-9 text-white;
}
.signout > * {
  @apply hidden lg:block cursor-pointer hover:text-secondary my-auto;
}
/* Signout ends */

/* 
  * Autofill
  * Resetting default browser styles on autofill
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #000 inset;
  opacity: 50%;
  -webkit-text-fill-color: white;
}

/*
  * Map
  * Below are classes for map point selection popups
*/

.alert-level {
  @apply bg-active-alert rounded-full flex text-white items-center justify-center text-center;
}
.alert-level-active {
  @apply !bg-white !text-black font-bold;
}
.text-wa {
  @apply text-base leading-5 font-medium;
}
.level-wrapper {
  @apply flex flex-col items-center relative w-16 h-18;
}
.shadow-active-level-indicator {
  @apply flex flex-col place-content-center w-16 h-16 absolute -top-1 left-0 -z-10 rounded-full bg-active-alert;
}
.active-level-indicator {
  @apply h-4 w-3 bg-active-alert;
}
.level-info-wrapper {
  @apply flex flex-col place-content-center text-white text-center md:cursor-pointer align-middle top-2 overflow-hidden bg-active-alert rounded-full
            w-14 h-14 left-[0.375rem] text-[0.438rem] leading-[0.5rem];
}
.level-info {
  @apply left-0 text-xs break-all font-bold top-2 ml-10-percent mr-10-percent;
}
.wl-text-size {
  @apply !text-[0.85rem];
}
.notif-type-wrapper {
  @apply text-wa w-full flex content-between p-3 text-white bg-active-alert rounded-[35px] min-w-[17rem];
}
.notif-bell {
  @apply absolute right-0.5 top-0 md:right-2 md:top-0.5;
}
.btn-notif {
  @apply py-2.5 rounded-3xl bg-black bg-opacity-75 text-center;
}
.btn-notif-active {
  @apply !bg-white !text-black;
}
.marker-info-wrapper {
  @apply wa-flex flex-col w-full h-full text-white;
}
.text-level {
  @apply w-44 h-7 not-italic font-light leading-5 mb-8;
}

/* Google Maps styling */
.gm-style .gm-style-iw-c {
  @apply !p-3 !max-w-[400px] !bg-[#000000cc];
}
.gm-style-iw-d {
  @apply !overflow-auto;
}
.gm-ui-hover-effect {
  @apply opacity-100 hover:!opacity-80 rounded-half !right-0 !top-0;
}
.gm-ui-hover-effect > span {
  @apply bg-white;
}
/* End Maps styling */

/* Slider */
.slider-wrapper {
  @apply h-screen flex flex-nowrap overflow-x-hidden relative;
}
.slide {
  @apply h-full w-screen flex-shrink-0 bg-center bg-cover;
  transition: 800ms all ease-in-out;
}
.slider-footer {
  @apply flex flex-col flex-nowrap absolute items-center bottom-3.5 left-2/4;
  transform: translateX(-50%);
}
.dot {
  @apply w-3 h-3 rounded-md bg-black m-1 cursor-pointer;
  transition: 800ms all ease-in-out;
}
.btn-hint {
  @apply rounded-3xl bg-black bg-opacity-50 text-white mb-6 py-1 px-6 w-32 text-center;
}

/* End Slider */

/* Profile */
.text-profile {
  @apply text-base leading-5 font-normal not-italic text-[#767676];
}
.text-profile-links {
  @apply font-light italic text-[#104EC6];
}
/* end profile */

/* History */
.history-container {
  @apply mx-8 my-24 w-80 md:mt-32;
}

/* end History */

@screen sm {
  .custom-captcha {
    transform: scale(1.26);
  }
}

@screen md {
  .level-wrapper {
    @apply w-24 h-24;
  }
  .level-info-wrapper {
    @apply w-16 h-16 rounded-full;
  }
  .level-info {
    @apply top-3 text-[0.85rem] rounded-half max-w-80-percent;
  }
  .shadow-active-level-indicator {
    @apply w-18 h-18 -top-1 left-3;
  }
  .active-level-indicator {
    @apply h-8 w-3;
  }
  .wl-text-size {
    @apply !text-[0.9rem];
  }
  .gm-style .gm-style-iw-c {
    @apply !max-w-7xl;
  }
}
